<template>
	<div ref="tableBox" class="tool-table-box">
		<el-table
			ref="table"
			border
			v-loading.lock="loading"
			:header-cell-style="tableHeaderColor"
			:data="tableData"
			:row-key="rowKey"
			:default-expand-all="defaultExpandAll"
			:tree-props="treeProps"
			txt-align="center"
			element-loading-text="拼命加载中"
			@selection-change="handleSelectionChange"
			@sort-change="sortChange"
			:style="{ minHeight: tableHeight + 'px' }"
		>
			<el-table-column v-if="isShowSelection" type="selection" :selectable="selectable" width="50" align="center" />
			<el-table-column v-if="isShowIndex" type="index" width="60" label="序号" align="center" />
			<slot>
				<columnItem v-for="(item, index) in tableHead.filter((res) => !res.hidden)" :key="index" :item="item">
					<template v-for="prop in getChildrenProps(item)" #[prop]="{ scope }">
						<slot :name="prop" :scope="scope" />
					</template>
				</columnItem>
			</slot>
			<el-table-column v-if="showOperation" type="text" label="操作" :align="optionAlign" :width="operationColumnWidth">
				<template slot-scope="scope">
					<slot name="operation" :scope="scope" />
				</template>
			</el-table-column>
			<template slot="append">
				<slot name="append" />
			</template>
		</el-table>
		<div v-if="isShowPage" class="page-box">
			<el-pagination
				background
				layout="total, sizes, prev, pager, next, jumper"
				:page-sizes="[10, 20, 30, 100]"
				:page-size="pageSize"
				:current-page="currentPage"
				:total="total"
				@size-change="sizeChange"
				@current-change="currentChange"
			/>
		</div>
	</div>
</template>
<script>
export default {
	components: {
		columnItem: () => import('./columnItem'),
	},
	data() {
		return {};
	},
	props: {
		//是否展示加载的遮挡层
		loading: {
			type: Boolean,
			default: false,
		},
		//是否展示表格下方的分页
		isShowPage: {
			type: Boolean,
			default: true,
		},
		//是否展示序号
		isShowIndex: {
			type: Boolean,
			default: true,
		},
		//是否展示选择框
		isShowSelection: {
			type: Boolean,
			default: false,
		},
		//是否展开所有行
		defaultExpandAll: {
			type: Boolean,
			default: false,
		},
		//行数据的 Key，用来优化 Table 的渲染
		rowKey: {
			type: String,
			default: 'id',
		},
		//表格获取的列表数据
		tableData: {
			type: Array,
			default: () => {
				return [];
			},
		},
		//表头属性和字段的设置
		tableHead: {
			type: Array,
			default: () => {
				return [];
			},
		},
		//总条数
		total: {
			type: Number,
			default: 0,
		},
		//当前页数
		currentPage: {
			type: Number,
			default: 1,
		},
		//当前请求数量
		pageSize: {
			type: Number,
			default: 10,
		},
		//操作栏宽度
		operationColumnWidth: {
			type: Number,
			default: 120,
		},
		//是否展示操作栏
		showOperation: {
			type: Boolean,
			default: true,
		},
		//渲染嵌套数据的配置选项
		treeProps: {
			type: Object,
			default: () => {
				return { hasChildren: 'hasChildren', children: 'children' };
			},
		},
		// 最小高度
		tableHeight: {
			type: Number,
			default: 300,
		},
		// 操作栏的对齐方式
		optionAlign: {
			type: String,
			default: 'center',
		},
		enabledColumnWidth: {
			type: Number,
			default: 110,
		},
	},
	methods: {
		tableHeaderColor({ rowIndex }) {
			if (rowIndex === 0) {
				return 'background-color: #f5f5f5;font-size:14px;font-weight: 500;';
			}
		},
		sortChange(e) {
			this.$emit('sort-change', e);
		},
		getChildrenProps(item) {
			let props = [];
			const func = (item) => {
				if (item.children && item.children.length > 0) {
					item.children.forEach((res) => {
						if (res.children && res.children.length > 0) {
							this.getChildrenProps(res.children);
						} else {
							if (res.prop && this.$scopedSlots[res.prop]) {
								props.push(res.prop);
							}
						}
					});
				} else {
					if (item.prop && this.$scopedSlots[item.prop]) {
						props.push(item.prop);
					}
				}
			};
			func(item);
			return props;
		},
		selectable(row) {
			// 默认不禁用  如果想要禁用的话  请把想要禁用的那一条数据里手动添加 isSelection = 1 这个字段
			let type = 0;
			if (row.isSelection) {
				type = row.isSelection;
			}
			return type === 0;
		},
		sizeChange(val) {
			this.$emit('update:current-page', 1);
			this.$emit('update:page-size', val);
			this.$emit('change-page', { current: 1, size: val });
		},
		currentChange(val) {
			this.$emit('update:current-page', val);
			this.$emit('change-page', { current: val, size: this.pageSize });
		},
		handleSelectionChange($event) {
			this.$emit('selection-change', $event);
		},
		//表格错位问题
		doLayout() {
			this.$nextTick(() => {
				this.$refs.table.doLayout();
			});
		},
	},
	created() {
		// this.tableHeight = this.tableMaxHeight
	},
	beforeUpdate() {
		this.doLayout();
	},
	watch: {
		tableData() {
			let me = this;
			setTimeout(() => {
				me.doLayout();
			}, 100);
		},
	},
};
</script>
<style lang="scss" scoped>
// ::v-deep .el-table{
//   min-height: calc(100vh - 300px);

// }
::v-deep .el-table__cell {
	color: #333 !important;
}
.el-table {
	overflow: auto;
}
.tool-table-box {
	height: 100%;
	background: #fff;
	flex: 1;
}
.page-box {
	// position: fixed;
	// bottom: 0;
	height: 80px;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	.el-pagination {
		// margin-left: -250px;
	}
}
</style>
